import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
  map: null,
  pending: true,
  touched: false,
  p65: {touched: false, pending: true, data: []},
};

const safetyDataSheetSlice = createSlice({
  name: 'safetyDataSheet',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setMap: (state, action) => {
      state.map = action.payload;
    },
    setP65: (state, action) => {
      state.p65 = {...state.p65, ...action.payload};
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
    forgetState: (state, action) => {
      state.data = initialState.data;
      state.pending = initialState.pending;
      state.touched = initialState.touched;
    },
  },
});

export const safetyDataSheetSliceActions = safetyDataSheetSlice.actions;

export default safetyDataSheetSlice.reducer;
